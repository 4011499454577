.content {
  composes: pt-3 px-2 from global;
}

.title {
  composes: mb-2 from global;
  font-size: 24px;
  color: var(--c-black-100);
}

.text {
  composes: mb-2 from global;
  font-size: 14px;
  line-height: 1.43;
  color: var(--c-black-87);
}

.stats {
  composes: mb-2 from global;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 20px 0;
}

.stat {
  composes: flex items-center from global;
  height: 30px;
}

.icon {
  composes: mr-2 from global;
  height: 30px;
  width: 30px;
}

.rating {
  composes: flex from global;
  align-items: baseline;
}

.count {
  font-size: 24px;
  margin-right: 10px;
  font-weight: bold;
}

.cta {
  composes: p-2 text-center font-bold block from global;
  border-top: 1px solid #f1f1f1;
  color: var(--c-primary);
  font-size: 18px;
}
