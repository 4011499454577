@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.row {
  composes: clearfix from global;

  @media mBreakpointSm {
    margin-left: calc(-1 * 0.5rem);
    margin-right: calc(-1 * 0.5rem);
  }
}

.column {
  composes: col col-12 pt-2 from global;

  @media mBreakpointSm {
    composes: px-1 from global;
  }
}

.column6 {
  composes: column;
  composes: sm-col-6 from global;
}
