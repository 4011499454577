.rating-button {
  composes: flex items-center justify-center from global;
  flex: 1 1 25%;
  cursor: pointer;
  outline: none;
  font-weight: bold;
  font-size: 14px;
  height: 40px;
  border: none;
  background: none;
  padding: 8px 4px;
  border-right: 1px solid #d9d9d9;

  &.selected {
    color: #fff !important;

    & svg {
      & [fill^='#'] {
        fill: #ffffff;
      }

      & [stroke^='#'] {
        stroke: #ffffff;
      }
    }
  }
}

.ratings-container {
  composes: flex from global;
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #d9d9d9;
  border-radius: 4px;

  & > button:nth-child(1) {
    color: #087bcc;

    &.selected {
      background-color: #087bcc;
    }
  }

  & > button:nth-child(2) {
    color: #7a60c1;

    &.selected {
      background-color: #7a60c1;
    }
  }

  & > button:nth-child(3) {
    color: var(--c-error);

    &.selected {
      background-color: var(--c-error);
    }
  }

  & > button:nth-child(4) {
    color: #626262;
    background-color: var(--c-black-3);

    &.selected {
      background-color: #626262;
    }
  }

  & > button:last-of-type {
    border-right: none;
  }
}

.icon {
  margin-right: 5px;
}
