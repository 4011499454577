.container {
  composes: flex from global;
  border: 1px solid var(--c-warning);
  border-radius: 4px;
}

.warning {
  border-color: var(--c-warning);

  & .icon-container {
    background-color: var(--c-warning);
  }
}

.error {
  border-color: var(--c-error);

  & .icon-container {
    background-color: var(--c-error);
  }
}

.icon-container {
  composes: flex items-center from global;
  padding: 32px 8px;
  background-color: var(--c-warning);
}

.text-container {
  composes: text-left from global;
  padding: 16px 12px;
}

.header {
  composes: body-medium from global;
  font-weight: bold;
}

.text {
  composes: body-small from global;
  margin-top: 4px;
  color: var(--c-black-87);
}
