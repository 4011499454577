.tip {
  display: grid;
  gap: 16px;
  padding: 16px 16px 32px;
  width: 100%;
  grid-auto-rows: max-content;
  text-align: center;
  justify-content: center;
}

.toggle-group {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  align-items: center;
  height: 44px;

  & > button:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  & > button:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.toggle {
  height: 100%;
  border: 1px solid var(--color-neutrals-300);
}

.selected {
  background-color: var(--color-learn-blue-100);
  border-color: var(--color-learn-blue-500);
}
