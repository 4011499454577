.messages {
  composes: flex flex-col from global;
  &:nth-child(2) {
    margin-top: 20px;
  }
}

.card {
  composes: flex flex-col items-center from global;
  min-height: 200px;
}

.sellable {
  composes: flex flex-auto p-2 from global;
  width: 100%;
}

.image {
  object-fit: cover;
}

.image-container {
  flex: 0 0 30%;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.details {
  composes: flex-auto ml-2 from global;
  width: 100%;
}

.title {
  composes: font-bold from global;
  font-size: 14px;
  color: var(--c-black-100);
  line-height: 1.43;
}

.message {
  composes: relative from global;
}

.view-link {
  composes: mt-2 from global;
  font-size: 14px;
  font-weight: bold;
  color: #626262;
  text-decoration: underline;
}

.pricing {
  composes: mb-2 from global;
  font-size: 16px;
  color: var(--c-black-100);
}

.savings {
  composes: ml-1 from global;
  font-size: 16px;
  color: #626262;
  text-decoration: line-through;
}
