@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.powered-by-google {
  composes: mt-1 pr-1 text-right from global;
}

.suggestion-container {
  background-color: #fff;
  width: calc(100% - 32px);
  box-shadow: none;
  border: none;
  margin-top: 24px;

  @media mBreakpointSm {
    margin-top: -3px;
    border-radius: 3px;
    overflow-y: auto;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
    border: solid 1px rgba(0, 0, 0, 0.08);
    width: 100%;
    max-height: unset;
  }
}
