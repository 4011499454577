.overlay {
  composes: p-2 flex flex-col absolute top-0 right-0 bottom-0 left-0 from global;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 9;
}

.title {
  margin-bottom: 8px;
  font-size: 18px;
  color: #fff;
}

.reasons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 0 10px;
}

.reason-button {
  composes: mb-2 from global;
  line-height: 1;
  justify-content: flex-start !important;

  &:hover {
    border-color: var(--c-primary) !important;
  }
}

.selected {
  border: 3px solid var(--c-primary);
  color: var(--c-primary);
}

.submit-row {
  composes: flex justify-end from global;
  width: 100%;

  & > button:first-of-type {
    margin-right: 10px;
  }

  & > button {
    width: 85px;
  }
}

.button-clear {
  color: #ffffff;
  border: 1px solid #ffffff;
  background-color: transparent;

  &:hover {
    background-color: rgba(255, 255, 255, 0.16);
  }

  &:active {
    background-color: transparent;
  }
}
