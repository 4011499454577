.modal-content {
  display: grid;
  min-height: 580px;
  grid-template-rows: 0fr 1fr;
}

.header {
  display: grid;
  gap: 16px;
  padding: 24px 16px 0px 16px;
}

.expert-showcase {
  composes: flex flex-col from global;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  aspect-ratio: 16/9;
  border-radius: 16px;
  background: var(--color-overlay-solid);
  overflow: hidden;
  max-height: 240px;
  width: 100%;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: var(--color-overlay-solid);
  z-index: -1;
}

.details {
  width: 100%;
  height: 100%;
}
