@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.form-container {
  composes: flex flex-col from global;
  gap: 24px;
}

.bottom-padding-large {
  padding-bottom: 40px;
}

.address-button {
  width: fit-content;
  font-weight: 400;
}

.shipping-heading-container {
  composes: flex from global;
  margin-top: 25px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
}

.shipping-heading {
  flex-grow: 1;
}

.shipping-cancel {
  color: var(--c-secondary);
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  flex-shrink: 0;
}
