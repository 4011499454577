@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.row {
  composes: clearfix from global;

  @media mBreakpointSm {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
}

.columnFull {
  composes: col col-12 pt-2 from global;

  @media mBreakpointSm {
    composes: px-1 from global;
  }
}

.saveAddress {
  margin-top: 20px;
  font-size: 14px;
}

.verify-address-warning-container {
  margin-top: 20px;
}
